import React from "react"
import Layout from "../components/layout"

const questions = [
  {
    question: `How much does this service cost?`,
    answer: `Refresh is totally free to use!`
  },
  {
    question: `When will you be adding more products?`,
    answer: `Since it takes a lot of computing power to monitor each product, we add products on a case-by-case basis. Join our community to request one, and we'll see what we can do!`
  },
  {
    question: `How can I request more products?`,
    answer: `Come join our community to request a product.`
  }
];

const Question = ({ question, answer }) => (
  <div className="question">
    <h4>{question}</h4>
    <p>{answer}</p>
  </div>
)

export default function Faq() {
  const questionsMarkup = questions.map((question, index) => 
    <Question 
      key={index}
      question={question.question}
      answer={question.answer}
    />
  );
  return (
    <Layout blog={true}>
      <div className="faq full-page-module">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-questions">
          {questionsMarkup}
        </div>
      </div>
    </Layout>
  )
}